import { useCallback } from 'react';

import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { storyblokInit, apiPlugin } from '@storyblok/react';
import { AnimatePresence } from 'framer-motion';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { getSession, SessionProvider } from 'next-auth/react';
import { IntlProvider } from 'react-intl';

import BlogPost from '@/components/storyblok/pages/Blog';
import RichText from '@/components/storyblok/sections/RichText';
import config from '@/config';
import { AuthProvider } from '@/context/AuthContext';
import { EnquiryNotificationProvider } from '@/context/EnquiryNotificationContext';
import { IntercomProvider } from '@/context/IntercomContext';
import { TrackingProvider } from '@/context/TrackingContext';
import { useApollo } from '@/graphql/apollo';
import { CustomSession } from '@/server/models/auth';
import theme from '@/theme';

import en from '../lang/en.json';
import fr from '../lang/fr.json';

storyblokInit({
  accessToken: `${process.env.NEXT_PUBLIC_STORYBLOK_TOKEN}`,
  // bridge: false,
  // apiOptions: {  },
  use: [apiPlugin],
  components: {
    page: BlogPost,
    richtext: RichText,
  },
});

const languageTexts = {
  en,
  fr,
};

export default function App({
  Component,
  pageProps: { session: sessionProp, ...pageProps },
}): JSX.Element {
  const getAuthToken = useCallback(async () => {
    const session = (await getSession()) as CustomSession;

    return session?.idToken || null;
  }, []);

  const apolloClient = useApollo(pageProps.initialApolloState, getAuthToken);

  const { locale } = useRouter();

  return (
    <Sentry.ErrorBoundary>
      <IntlProvider locale={locale} messages={languageTexts[locale]}>
        <ApolloProvider client={apolloClient}>
          <SessionProvider session={sessionProp}>
            <TrackingProvider>
              <ChakraProvider resetCSS theme={theme}>
                <AuthProvider clearCache={() => apolloClient.clearStore()}>
                  <IntercomProvider>
                    <EnquiryNotificationProvider>
                      <>
                        <Head>
                          <title>{config.title}</title>
                          <meta
                            content='width=device-width, initial-scale=1'
                            name='viewport'
                          />
                          <meta content='ie=edge' httpEquiv='x-ua-compatible' />
                        </Head>
                        <AnimatePresence>
                          <Component {...pageProps} />
                        </AnimatePresence>
                      </>
                    </EnquiryNotificationProvider>
                  </IntercomProvider>
                </AuthProvider>
              </ChakraProvider>
            </TrackingProvider>
          </SessionProvider>
        </ApolloProvider>
      </IntlProvider>
    </Sentry.ErrorBoundary>
  );
}
