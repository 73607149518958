import { createContext, useContext, useEffect, useState } from 'react';

import { usePathname } from 'next/navigation';

import { useTransactionRecordsNotificationCounterLazyQuery } from '@/generated/core.graphql';

import { useAuth } from './AuthContext';

interface EnquiryNotificationContextProps {
  sellerNotificationCount: number;
  buyerNotificationCount: number;
}

const EnquiryNotificationContext = createContext<
  EnquiryNotificationContextProps | undefined
>(undefined);

export const useEnquiryNotification = () => {
  const context = useContext(EnquiryNotificationContext);
  if (context === undefined) {
    throw new Error(
      'useEnquiryNotification must be used within an EnquiryNotificationProvider'
    );
  }
  return context;
};

export const EnquiryNotificationProvider = ({ children }) => {
  const { self, isLoading: isAuthLoading } = useAuth();
  const pathname = usePathname();

  const [
    queryTransactionRecordsNotificationCounter,
    { data, loading, refetch },
  ] = useTransactionRecordsNotificationCounterLazyQuery();

  const [sellerNotificationCount, setSellerNotificationCount] = useState(0);
  const [buyerNotificationCount, setBuyerNotificationCount] = useState(0);

  useEffect(() => {
    if (!isAuthLoading && self?.id) {
      queryTransactionRecordsNotificationCounter({
        variables: {
          options: {
            userId: self.id,
          },
        },
      });
    }
  }, [
    self?.id,
    isAuthLoading,
    queryTransactionRecordsNotificationCounter,
    pathname,
  ]);

  useEffect(() => {
    if (!isAuthLoading && self?.id) {
      refetch();
      setBuyerNotificationCount(
        data?.transactionRecordsNotificationCounter?.buyerNotificationCount ?? 0
      );
      setSellerNotificationCount(
        data?.transactionRecordsNotificationCounter?.sellerNotificationCount ??
          0
      );
    }
  }, [data, isAuthLoading, loading, pathname]);

  return (
    <EnquiryNotificationContext.Provider
      value={{
        sellerNotificationCount,
        buyerNotificationCount,
      }}
    >
      {children}
    </EnquiryNotificationContext.Provider>
  );
};
