/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { FC, useCallback, useEffect } from 'react';

import {
  Identify,
  init,
  setUserId as ampSetUserId,
  track,
  add,
  reset as ampReset,
  identify as ampIdentify,
} from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import Config from '@/config';
import { User } from '@/generated/core.graphql';

import { TrackingParams } from './TrackingParams';

type UserProperties = {
  userId?: number;
  isTestUser?: boolean;
  firstName?: string;
};

type TrackingContext = {
  trackEvent: (config: TrackingParams) => Promise<void>;
  setUserProperties: (properties: UserProperties) => void;
  identify: (user: Partial<User>) => void;
  reset: () => void;
};

let isAmplitudeEnabled = false;

let isPostHogEnabled = false;

// eslint-disable-next-line no-redeclare
const TrackingContext = React.createContext<TrackingContext>({
  trackEvent: () => Promise.resolve(),
  setUserProperties: () => undefined,
  identify: () => undefined,
  reset: () => undefined,
});

const TrackingProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  useEffect(() => {
    init(Config.amplitudeApiKey, null, { minIdLength: 1 });
    isAmplitudeEnabled = true;

    if (isAmplitudeEnabled) {
      const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 1 });

      add(sessionReplayTracking);
    }

    if (typeof window !== 'undefined') {
      // checks that we are client-side
      posthog.init(Config.postHogApiKey, {
        api_host: Config.postHogHost || 'https://eu.i.posthog.com',
        person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
        loaded: (posthog) => {
          if (process.env.NODE_ENV === 'development') posthog.debug(); // debug mode in development
          isPostHogEnabled = true;
        },
      });
    }
  }, []);

  const trackEvent = useCallback(async (options: TrackingParams) => {
    if (isAmplitudeEnabled) {
      track(options.event, (options as any).eventData);
    }

    if (isPostHogEnabled) {
      posthog.capture(options.event, (options as any).eventData);
    }

    // GTM
    if (window.dataLayer) {
      window.dataLayer.push({ event: options.event });
    }
  }, []);

  const setUserProperties = useCallback((params: UserProperties) => {
    if (isAmplitudeEnabled) {
      const identityEvent = new Identify();

      Object.keys(params).forEach((key) => {
        if (params[key] !== null && params[key] !== undefined) {
          identityEvent.set(key, params[key]);
        }
      });

      ampIdentify(identityEvent);
    }

    if (isPostHogEnabled) {
      Object.keys(params).forEach((key) => {
        if (params[key] !== null && params[key] !== undefined) {
          posthog.capture('$set', {
            $set: { [key]: params[key] },
          });
        }
      });
    }
  }, []);

  const identify = useCallback((user: Partial<User>) => {
    const userIdStr = user.id.toString();

    const personProperties = {
      firstName: user.profile?.firstName,
      lastName: user.profile?.lastName,
      email: user.email || '',
      userType: user.profile?.reasonForJoining,
    };

    if (isAmplitudeEnabled) {
      ampSetUserId(userIdStr);
      if (user.profile?.firstName) {
        setUserProperties(personProperties);
      }
      if (user.profile?.reasonForJoining) {
        setUserProperties(personProperties);
      }
      if (user.profile?.lastName) {
        setUserProperties(personProperties);
      }
      if (user.email) {
        setUserProperties(personProperties);
      }
    }

    if (isPostHogEnabled) {
      posthog.identify(userIdStr, personProperties);
      setUserProperties(personProperties);
    }
  }, []);

  const reset = () => {
    if (isAmplitudeEnabled) {
      ampReset();
    }

    if (isPostHogEnabled) {
      posthog.reset();
    }
  };

  return (
    <PostHogProvider client={posthog}>
      <TrackingContext.Provider
        value={{
          trackEvent,
          setUserProperties,
          identify,
          reset,
        }}
      >
        {children}
      </TrackingContext.Provider>
    </PostHogProvider>
  );
};

function useTracking(): TrackingContext {
  const context = React.useContext(TrackingContext);
  if (context === undefined) {
    throw new Error('useTracking must be used within a TrackingProvider');
  }
  return context;
}

export { TrackingProvider, useTracking };
